@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root {
  --primary: #1f505b;
  --secondary: #37172c;
}
h1,h2,h3 {
  margin: 0;
}

b {
  font-size: 95%;
}


body {
  margin: 0;
  color: #333;
  font-family: 'Poppins';
  background: #f2f2f2;
}
nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: center;
}
nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}
nav h1 {
  color: #fff;
}
.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.database-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}

.database-card {
  width: 100%;
  padding: 10px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
}

.database-card .rating {
  position: absolute;
  top: -10px;
  right: -10px;
  background: var(--secondary);
  color: #fff;
  border-radius: 6px;
  width: 40px;
  height: 0;
  padding:20px 0;
  line-height: 0px;
  text-align: center;
}

.checked {
  content: '✓';
}

.unchecked {
  content: '☐';
}

.checkbox-container {
  display: flex-end;
  align-items: center;
  justify-content: space-between;
}

.checkbox-container input[type="checkbox"] {
  margin-block: 10px; /* Add some spacing between the checkbox and the label */
}


.integration-card {
  width: 100%;
  padding: 10px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
}

.integration-card .rating {
  position: absolute;
  top: -10px;
  right: -10px;
  background: var(--secondary);
  color: #fff;
  border-radius: 6px;
  width: 40px;
  height: 0;
  padding:20px 0;
  line-height: 0px;
  text-align: center;
}

/* forms */
form {
  background: #fff;
  padding: 20px;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 6px;
}

form input, form textarea {
  display: block;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 10px 0 20px 0;
}

form button {
  background: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
}

.database-card .buttons {
  text-align: right;
}

.database-card i {
  color: #bbb;
  margin-left: 10px;
  font-size: 1.2em;
  padding: 6px;
  background: #eee;
  border-radius: 50%;
  cursor: pointer
}

.integration-card .buttons {
  text-align: right;
}

.integration-card i {
  color: #bbb;
  margin-left: 10px;
  font-size: 1.2em;
  padding: 6px;
  background: #eee;
  border-radius: 50%;
  cursor: pointer
}

.order-by button {
  margin-right: 10px;
  background: var(--primary);
  color: #fff;
  border: 0;
  padding: 4px 8px;
  border-radius: 6px;
  font-family: "Poppins";
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
  margin-bottom: 20px;
}

.modal-input {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  background: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
  margin-left: 10px; /* For space between buttons */
}